import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'bulma/css/bulma.css'

import './App.css'
import Index from './index'
import Error from './Error'
import Meeting from './meeting'
import WaitingScreen from '../../src/components/WaitingScreen/WaitingScreen';
import SessionScreen from '../../src/components/WaitingScreen/SessionScreen';
import ThankScreen from '../../src/components/AgoraVideoCall/ThanksScreen';
import QuitScreen from '../../src/components/AgoraVideoCall/QuitScreen';
import AgoraError from '../../src/components/AgoraVideoCall/AgoraLocalStreamError';
import PermissionDenied from '../../src/components/AgoraVideoCall/PermissionDenied';
import NetworkDetector from '../../src/NetworkDetector/NetworkDetector';


class App extends Component {
  render() {
    return (

        <div className="full videocall">
      <BrowserRouter >
          <Switch>
            <Route path="/meeting/:user/:id" component={Meeting} />
            <Route path="/:user/:userId" component={WaitingScreen} />
            <Route path="/thanks" component = {ThankScreen}/>
            <Route path="/quit" component = {QuitScreen}/>
            <Route path="/permission" component = {PermissionDenied}/>
            <Route path="/error" component = {AgoraError}/>
            <Route  component={Error} /> 
          </Switch>
        </BrowserRouter>
    </div>
    );
  }
}

export default App;
