import React from 'react'

import * as Cookies from "js-cookie";
import '../../assets/css/bootstrap.min.css';
import './waitingscreen.css';
import { Alert } from 'react-alert'
import axios from 'axios';
import Loader from './loader';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import NetworkDetector from '../../NetworkDetector/NetworkDetector';
import countdownimg from '../../assets/images/bg-image.png'
import logo from '../../assets/images/logo.png'
import Moment from 'moment';
import { APP_BASEURL } from "../../agora.config";
import moment from 'moment'

class WaitingScreen extends React.Component {

  
  constructor(props) {
    super(props)
    
    this.state = {
      appointmentData : "",
      isReady:false,
      currentUser: '',
      loader: false,
      errorMessage : '',
      serverTime:new Date(),
      day:'',
      hour:'',
      min:'',
      sec:'',
    }

    this.notify = this.notify.bind(this);
   
  }

  notify( min) {
     this.setState({isReady: min});
  }
getOrgTime=()=>{
  console.log(APP_BASEURL+'api/caremeapp/org_current_time/')
  axios.get(APP_BASEURL+'api/caremeapp/org_current_time/',{
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
  },
  }).then((res)=>{
    this.setState({serverTime:res.data.data})
  })
}

  //localhost:3000/value1/value2
  componentDidMount() {
   
        this.interval = setInterval(() =>  {this.getOrgTime();this.getTotalTime()}, 500);

  }
  componentDidUpdate() {
    // rerendering
    
  }

  componentWillUnmount () {
    clearInterval(this.interval);

  }
getTotalTime=()=>{
  console.log('my props', this.props,moment(new Date()).format('YYYY-MM-DDThh:mm:ss'));
  var user =  this.props.match.params.user; //value1

  console.log('user name',user);
  this.setState({currentUser: user, loader: false });
  var appoinmentKey =  this.props.match.params.userId; //value 2
  console.log("Meeting Id " + appoinmentKey);
    
//       debugger;
  console.log('API Fetch', APP_BASEURL+'api/caremeapp/decrypt_videoid/')
  axios.post(APP_BASEURL+'api/caremeapp/decrypt_videoid/',{'room_id': appoinmentKey},{
    headers: {
        'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29',
        'Content-Type': 'application/json',
    }
    })
      .then(response => {
        this.setState({ appointmentData: response.data.data[0] }, () => {
          console.log(this.state.appointmentData, 'appointmentData');
        }); 

        var currentDate = moment(this.state.serverTime).valueOf();
        console.log(currentDate, moment(this.state.serverTime).valueOf())
        var dateTime = this.state.appointmentData.appointment_date + 'T' + this.state.appointmentData.appointment_time;
         //var dateTime = '2021-03-16' + 'T' + '22:30:10';
         var appointmentDate = moment(dateTime).valueOf();
        console.log(appointmentDate)

        var distance = appointmentDate - currentDate;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        this.setState({
          day:days,
          hour:hours,
          min:minutes,
          sec:seconds
        })
        var getMinutes =  10*60*1000;
       if (user == "doctor"){
        if (this.state.appointmentData.url_status == "valid"){
        if(distance < getMinutes){
          this.props.history.push("/meeting/"+user +"/"+appoinmentKey , { appointmentData: this.state.appointmentData, user: user});
          this.setState({ loader: false, errorMessage : '' });
        }
        else{
            // console.log('Data' , getTimer(this.props.history,this.state.appointmentData, user, this.notify, appoinmentKey, this.state.serverTime) + "respone" , this.state.appointmentData);
            console.log(this.state.appointmentData);
            this.setState({ loader: false, errorMessage : '' });
          }
        }
        else{
          this.setState({ loader: true, errorMessage : '' });
          alert('meeting expired');
        }
      }
          else if (user == "patient"){
           if (this.state.appointmentData.url_status == "valid"){
            if(distance < getMinutes){
              this.props.history.push("/meeting/"+user +"/"+appoinmentKey , { appointmentData: this.state.appointmentData, user: user});
              this.setState({ loader: false, errorMessage : '' });
            }
            else{
              // console.log('Data' , getTimer(this.props.history,this.state.appointmentData, user, this.notify, appoinmentKey,this.state.serverTime) + "respone" , this.state.appointmentData);
              console.log(this.state.appointmentData);
              this.setState({ loader: false, errorMessage : '' });
            }
            }
            else{
              this.setState({ loader: true, errorMessage : '' });
              alert('meeting expired');
            }
          }
         else{
           alert('Meeting expired');
         }
      })
      .catch(error => {
          this.setState({ errorMessage: error.message, loader: false });
          console.error('There was an error!', error);
          this.props.history.push('/')
      });
}

  render() {

    const { isReady, currentUser, loader, day, min, sec, hour } = this.state;
   
    return (
      
     <div className="wait-screen-wrapper container-fluid">
      <img src={logo} className="logo_wait" />
      { loader ?  <Loader />:
      <div id="waitingscreen" className="row align-items-center">
        
        <div className="col-md-5 timerwrapper mx-auto">  
        <div className="row m-0">
          <div className="wait-timer col-sm-12 col-12 col-lg-10 px-0 mx-auto col-sm-push-12 order-lg-12  " >
          <table>
           <tr id="clk-div">
            <td> <p id="days"> {day}  </p> </td>
            <td id="hours"> {hour}</td>
            <td id="minutes"> {min}</td>
            <td id="seconds">{sec}</td>
          </tr>

          
            <tr id="clockdiv">
    
            <td className="days">
  
            Days
  
            </td>
  
            <td className="hours">
  
Hours
  
            </td>
  
            <td  className="minutes">
  
           Mins
  
            </td>
  
            <td  className="seconds">
  
           Sec
  
          </td>
          </tr>
          </table>
        
          </div>
          
          <div className="textWrapper col-sm-12 col-lg-12 px-0 col-sm-push-1 order-lg-1">
          
          {/* <p>Good things happen to those who wait </p> */}
    
          <p>Your Appoinment is about to begin in</p>                              
       </div>
       </div>
           { 
             isReady ? <div className={'notify'}>
                <p>{ currentUser !== "patient" ? "Thank you for waiting. We'll start the meeting when the client joins. Do you want to let the client know you are waiting" : "Thank you for waiting.We'll start the meeting when the provider joins. Do you want to let the provider know you are waiting"}</p>
                <button className={"notify-btn"} onClick={()=> this.notifyAction()}>{currentUser !== "patient" ? "Notify client" : 'Notify provider'} </button>

              </div> :''
              }
        </div>
{/*         
          <div className="col-md-3  ">        
              <img src={countdownimg} alt=""/>
          </div> */}
        </div>
       }
      </div>
      
    )
  }
}



export default WaitingScreen;


const getTimer = (history,appointmentInfo,user, notify, encryptkey, serverTime)=>{

  //debugger;
  var appKey = appointmentInfo.appointment_n_key;
// Set the date we're counting down to
var dateTime = appointmentInfo.appointment_date + 'T' + appointmentInfo.appointment_time;
//var dateTime = '2021-02-11' + ' ' + '15:00:00'
console.log("History ",appKey,'Date and Time',dateTime, serverTime);

//var dateTime = '2020-12-14' + 'T' + '10:10:00';
var countDownDate = new Date(dateTime).getTime();

// Update the count down every 1 second
var x = setInterval(function() {

  // Get today's date and time
  var now = new Date(serverTime).getTime();
  // var now = moment(serverTime).format()
  // Find the distance between now and the count down date
  var distance = countDownDate - now;
  console.log("History 1 ",countDownDate , 'now date',new Date());
  console.log("now time "+now);
  console.log("distance "+distance);

  
 

  // // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);
  
  
  var getMinutes =  10*60*1000;
  if(distance < getMinutes){
    clearInterval(x);
    history.push("/meeting/"+user +"/"+encryptkey , { appointmentData: appointmentInfo, user: user});
     //notify(true);
  }

  

  // Display the result in the element with id="demo"
  // document.getElementById("demo").innerHTML = days + "d " + hours + "h "
  // + minutes + "m " + seconds + "s ";

  // If the count down is finished, write some text
  if (distance < 0) {
    clearInterval(x);
    console.log('History psh' + history + appKey,appointmentInfo);
    // this.props.history.push('/agorademo/sjd@5dfkjsd@j')
    // history.push("/sessionscreen", {appointmentData: appointmentData, user: user});
    
    history.push("/meeting/"+user +"/"+encryptkey , { appointmentData: appointmentInfo, user: user});

    // document.getElementById("demo").innerHTML = "EXPIRED";
  }

   

  if(days.toString().length < 2){
    if(document.getElementById("days").innerHTML !== null){
      document.getElementById("days").innerHTML  = '0'+days;
    }
    
  }else{
    if(document.getElementById("days") !== null){
     document.getElementById("days").innerHTML  = days;
    }
  }
  
  if(hours.toString().length < 2){
    if(document.getElementById("hours") !== null){
    document.getElementById("hours").innerHTML  = '0'+hours;
    }
  }else{
    if(document.getElementById("hours") !== null){
    document.getElementById("hours").innerHTML  = hours;
    }
  }

  if(minutes.toString().length < 2){
    if(document.getElementById("minutes")!== null){
    document.getElementById("minutes").innerHTML  = '0'+minutes;
    }
  }else{
    if(document.getElementById("minutes") !== null){
    document.getElementById("minutes").innerHTML  = minutes;
    }
  }

  if(seconds.toString().length < 2){
    if(document.getElementById("seconds") !== null){
    document.getElementById("seconds").innerHTML  = '0'+seconds;
    }
  }else{
    if(document.getElementById("seconds") !== null){
      document.getElementById("seconds").innerHTML  = seconds;
    }
  }

  document.getElementById("hours").innerHTML  = hours;
  document.getElementById("minutes").innerHTML  = minutes;
  document.getElementById("seconds").innerHTML  = seconds;
   
  //return countdown;

}, 1000);

}
