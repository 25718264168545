import React from 'react'

import * as Cookies from "js-cookie";
import '../../assets/css/bootstrap.min.css';
import { Alert } from 'react-alert'
import axios from 'axios';
import security from '../../assets/images/web-security-icon-shield-lock-symbol-guard-badge-vector-16869686.png'
import { APP_BASEURL } from "../../agora.config";
import logo from '../../assets/images/waiting.jpeg'
class SessionScreen extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      appointmentData : "",
      notify:false
    }
  }
  setNotify = () =>{
     this.setState({
      notify: !this.state.notify
    })
    setTimeout(() => this.setState({ notify: false }), 60000);
    if(this.props.props != undefined){
      if(this.props.props.location.state){
    // if (this.props.props.location.state.user == "doctor"){

      const parameter = { "appointment_n_key": this.props.props.location.state.appointmentData.appointment_n_key,
      "patient_n_key": this.props.props.location.state.appointmentData.patient_n_key,
        "type":this.props.props.location.state.user
    };
        axios.post( APP_BASEURL + 'api/caremeapp/notify_sms/', parameter, { headers: {
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29',
          'Content-Type': 'application/json',
      }
      })
        .then((response) => {
          console.log('send sms response',response);
        }, (error) => {
          console.log(error);
        });
      }
    
  }
  }

  componentWillMount() {
    

  }
  //receive link -> baseurl/doctor/appointmentkey
  //receive link -> baseurl/patient/appointmentkey
  componentDidMount() {
    console.log('my props', this.props);
    // var appoinmentData = this.props.location.state.appoinmentData;
    // var appKey = this.props.location.state.appoinmentData.appointment_n_key;
    // var user = this.props.location.state.user;
  }
  
  componentWillUnmount () {
    
  }

  render() {

    
   
    return (
      

<div class="session-screen-wrapper text-center">
<div className='container'>
<div className="row align-items-center">
<div  className="col-md-10 col-11 mx-auto thankstxt"   >
        <img src={logo} className="logo" /><br></br>
   {/* <h2 className="text-uppercase">Please Wait</h2> */}
    <p className="thankstxt_wait">
    Waiting for the other participant to join
     </p>
     <button className="btn btn-primary" type="button" disabled={this.state.notify} onClick={this.setNotify} >Notify</button>
     {/* <div className="d-flex">
     <div className="col-md-3 col-2 px-0">
        <img src={security} />
     </div>
     <div className="col-md-9 col-10">
       <h5>Your meeting is safe
       </h5>
       <h6>No one can join a meeting unless invited or admitted by the host</h6></div>
     </div> */}
  </div>
  </div>
</div>
</div>
      
    );
  }
}

export default SessionScreen;
