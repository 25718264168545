import React from 'react'

import * as Cookies from "js-cookie";
import '../../assets/css/bootstrap.min.css';
import { Alert } from 'react-alert'
import axios from 'axios';

import logo from '../../assets/images/logo.png'
import security from '../../assets/images/web-security-icon-shield-lock-symbol-guard-badge-vector-16869686.png'

class ThanksScreen extends React.Component {
  constructor(props) {
    super(props)


  }

  componentWillMount() {


  }

  componentDidMount() {
    console.log('my props', this.props);

  }

  componentWillUnmount() {

  }

  render() {

    return (

      <div class="session-screen-wrapper text-center">
        <div className='container'>
        <div className="row align-items-center m-h100">
        <div  className="col-md-5 col-11 mx-auto thankstxt"   >
                <img src={logo} className="logo_wait" /><br></br>
                <br></br>
            <p>Thanks for Choosing CareMe Health 
              If you need any support please contact care team  at +91-7829-002-002 or email us at care@careme.health
             </p>
             <div className="d-flex">
             <div className="col-md-3 col-2 px-0">
                <img src={security} />
             </div>
             <div className="col-md-9 col-10">
               <h5>Your meeting is safe
               </h5>
               <h6>No one can join a meeting unless invited or admitted by the host</h6></div>
             </div>
          </div>
          </div>
        </div>
      </div>

    );
  }
}

export default ThanksScreen;
